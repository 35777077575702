export enum PageType {
    PRODUCT_PAGE = "product page",
    STORE_PAGE = "store page",
    RETAILER_PAGE = "retailer page",
    QNA_PAGE = "q&a",
    EDITORIAL_PAGE = "editorial content",
    COMPARISONS_PAGE = "comparisons",
    REVIEWS_PAGE = "reviews",
    WRITE_REVIEW_PAGE = "write review",
    STORE_LOCATOR = "store locator",
    QUIZ = "quiz",
    QUIZ_RESULTS = "quiz results",
    CASHBACK = "cashback",
    LIST_VIEW = "list",
    SIGNIN = "signin",
}

export enum PageSubType {
    MANUFACTURER = "manufacturer",
    LINE = "line",
    COLLECTION = "collection",
    MODEL = "model",
    RETAILER = "retailer",
    LOCATION = "location",
    REVIEWS_PAGE = "reviews",
    QUIZ = "quiz",
    QUIZ_RESULTS = "quiz results",
    MODELS = "models",
    STORES = "stores",
    BRANDS = "brands",
    DEALS = "deals",
    SIGNIN = "signin",
}

export enum PageRegion {
    SHOP_THIS_STORE = "shop this store",
    OTHER_NEARBY_STORES = "other nearby stores",
    SHOP_ONLINE = "shop online",
    REVIEWS = "reviews",
    REVIEWS_LIST = "reviews list",
    PRODUCTS = "products",
    DISCOUNTS = "discounts",
    STORES = "stores",
    STORE_LOCATOR = "store locator",
    STORE_LOCATOR_BEST_OFFER = "store locator best offer",
    BRANDS_LOCATOR_BEST_OFFER = "brands locator best offer",
    QUIZ = "quiz",
    QUIZ_RESULTS = "quiz results",
    QUIZ_RESULTS_NEARBY_STORES = "quiz results nearby stores",
    QUIZ_RESULTS_ONLINE_MATCHES = "quiz results online matches",
    QUIZ_RESULTS_LOCAL_MATCHES = "quiz results local matches",
    PRODUCT_QUICK_VIEW = "product quick view",
    STORE_QUICK_VIEW = "store quick view",
    MATTRESS_DEALS = "mattress deals",
    CASHBACK = "cashback",
    CASHBACK_REDEEM = "cashback redeem form",
    CASHBACK_LANDING = "cashback landing page",
    MATTRESS_REVIEWS = "mattress reviews",
    COMPARE = "compare",
    COMPARE_WIDGET = "compare widget",
    INDIVIDUAL_REVIEW = "individual review",
    INDIVIDUAL_REVIEW_COMMENTS = "individual review comments",
    MATTRESS_REVIEW = "mattress review",
    MATTRESS_REVIEW_THANKS = "mattress review thanks",
    MATTRESS_STORE_REVIEW = "mattress store review",
    MATTRESS_STORE_REVIEW_THANKS = "mattress store review thanks",
    SIGNIN = "signin",
    HEADER = "header menu",
    ONLINE_MODELS = "online models product list",
    PROMOS_TOASTER = "promos toaster",
    EDITORIAL_REVIEW_MODAL = "editorial review modal",
    SIDEBAR = "sidebar",
}

export enum CTAClickCategory {
    CALL_STORE = "call store",
    TEXT_STORE = "text store",
    RESEND_TEXT_STORE = "resend text store",
    TAKE_THE_QUIZ = "take the quiz",
    UPDATE_ZIP_CODE = "update zip code",
    SHOP_STORES = "shop stores",
    SHOP_FOR_BEDS = "shop for beds",
    OUTBOUND_LINK = "outbound link",
    DIRECTIONS = "directions",
    COMPARE = "compare",
    MATTRESS_REVIEW = "mattress review",
    MATTRESS_STORE_REVIEW = "mattress store review",
    CASHBACK_PROMOTION = "cashback promotion",
    BRAND_PROMOTION = "brand promotion",
    SALE_PROMOTION = "sale promotion",
    ONLINE_PROMOTION = "online promotion",
    FAVORITE = "favorite",
    SKIP_BUTTON = "skip button",
    LOAD_MORE_BUTTON = "load more button",
    PAGINATION_NEXT = "pagination next page",
    PAGINATION_PREV = "pagination previous page",
    SIGNIN = "signin",
    BACK_SIGNIN = "go back signin",
    RESET_SIGNIN = "reset signin",
    OPEN_COMMENTS_SECTION = "open comments section",
    DELETE_REVIEW_COMMENT = "delete review comment",
    SEARCH_REVIEW = "search review",
    CLEAR_REVIEW_FILTER = "clear review filter",
    SUBMIT_REVIEW_FILTER = "submit review filter",
    REPORT_REVIEW = "report review",
    OPEN_REPORT_REVIEW_MODAL = "open report review modal",
    RETURN_HOME = "return home page",
    SAVE_QUIZ_RESULTS = "save quiz results",
    SEND_QUIZ_RESULTS = "send quiz results",
    SHOW_QUIZ_RESULTS = "show quiz results",
    CHANGE_QUIZ_ANSWERS = "change quiz answers",
    PRODUCT_DETAIL_MODAL = "product detail modal",
    SUBMIT_QUIZ_RESULTS_FEEDBACK = "submit quiz resutls feedback",
    SHOW_MORE_MATTRESSES_FILTER = "show more mattresses filter",
    SEND_COMPARE_RESULTS = "send compare results",
    SHOW_COMPARE_RESULTS = "show compare results",
    SEND_COMPAPRE_RESULTS_EMAIL = "send compare by email",
    SEND_COMPARE_RESULTS_TEXT = "send comare by text",
    PRINT_COMPARE_RESULTS = "print compare results",
    SELECT_COMPARE_SIZE_CANCEL = "select compare mattress size cancel",
    SELECT_COMPARE_SIZE_SAVE = "save selected compare mattress size",
    CHANGE_COMPARE_SIZE = "change compare size",
    REMOVE_COMPARE_ITEM = "remove compare item",
    CASHBACK_EMAIL_REBATE = "cashback email rebate",
    CASHBACK_TEXT_REBATE = "cashback text rebate",
    CASHBACK_PRINT_REBATE = "cashback print rebate",
    CASHBACK_SEND_REMINDER = "cashback send reminder",
    SKIP_CASHBACK_REDEEM = "cashback skip redeem",
    CASHBACK_NEXT_STEP = "cashback next step",
    CASHBACK_PREVIOUS_STEP = "cashback previous step",
    CASHBACK_FINISH = "cashback finish",
    CASHBACK_SUBMIT = "cashback submit",
    DISCONTINUED_BUTTON = "discontinued button",
    INDIVIDUAL_REVIEW_SUBMIT_COMMENT = "individual review submit comment",
    STORE_DETAILS = "store details",
    READ_MORE = "read more",
    CLOSE_READ_MORE = "close read more",
    CLOSE_QUICK_VIEW = "close quick view",
    SCROLL_TOP_QUICK_VIEW = "scroll top quick view",
    QUICK_VIEW = "quick view",
    CLOSE_PRODUCT_DISCOUNT_MODAL = "close product discount modal",
    COUNTRY_USA = "USA country",
    COUNTRY_CANADA = "Canada country",
    OPEN_FILTER_MODAL = "open filter modal",
    BACK_FILTER_MODAL = "back filter modal",
    RESET_FILTERS = "reset filters",
    SEE_FILTER_RESULTS = "see filter results",
    FILTER_BUBBLE_REMOVE = "remove filter bubble",
    FILTER_CLEAR_ALL = "filter clear all",
    SEE_STORES = "see stores",
    SEE_NEARBY_STORES = "see nearby stores",
    FILTER_SECTION_CLICKED = "filter section clicked",
    VOTE = "vote",
    SHOW_BRANDS_AGAIN = "show brands again",
    BRAND_SELECTOR_SKIP_QUESTION = "brand selector skip question",
    BRAND_SELECTOR_GO_BACK = "brand selector go back",
    BRAND_SELECTOR_NONE_OF_THESE = "brand selector none of these",
    BRAND_SELECTOR_DONT_KNOW = "brand selector dont know",
    STORE_SELECTOR_SKIP_QUESTION = "store selector skip question",
    STORE_SELECTOR_GO_BACK = "store selector go back",
    ORDERED_VIA_WEBSITE_PHONE = "ordered via Website / Phone",
    RESET_ASYNC_SELECT = "reset async select",
    WIZARD_GO_BACK = "wizard go back",
    WIZARD_NEXT_STEP = "wizard next step",
    WIZARD_SUBMIT = "wizard submit",
    HEADER_SEARCH = "search",
    OPEN_UPDATE_ZIP_CODE_MODAL = "open update zip code modal",
    OPEN_HEADER_SIDE_MENU = "open header side menu",
    CLOSE_HEADER_SIDE_MENU = "close header side menu",
    HEADER_GO_TO_MAIN_MENU = "header go to main menu",
    SHOW_HEADER_SEARCH_INPUT = "show header search input",
    CHIP_DONT_KNOW = "chip don't know button",
    CHIP_CLICKED = "chip clicked",
    CHIP_IMAGE_CLICKED = "chip image clicked",
    GOODBEDS_REVIEW = "goodbed's review",
    STORE_LOCATOR_UPDATE_ZIP_CODE = "update zip code store locator",
    IN_DEPTH_REVIEWS = "in depth reviews",
    BORDER_BOX_PROMOTION = "border box promotion",
    CLOSE_POPOVER_MODAL = "close popover modal",
    CLOSE_MODAL = "close modal",
    SEE_MORE_RATINGS = "see more ratings",
    SEE_MORE_REVIEWS = "see more reviews",
    SEE_FULL_RATING = "see full rating",
    EXPAND_ONLINE_PROMO_DISCOUNT = "expand online promo discount",
    EXPAND_CASHBACK_PROMO_DISCOUNT = "expand cashback promo discount",
    EXPAND_RETAILER_PROMO_DISCOUNT = "expand retailer promo discount",
    OPEN_TOASTER = "open toaster",
    CLOSE_TOASTER = "close toaster",
    OPEN_BOTTOM_SHEET = "open bottom sheet",
    CLOSE_BOTTOM_SHEET = "close bottom sheet",
    TOASTER_UPDATE_ZIP_CODE = "update zip code toaster",
    EXIT_TOASTER = "exit toaster",
    SEE_ALL_DEALS = "see all deals",
    SAVE_DEAL = "save deal",
    SAVE_BRAND_LIST = "save brand list",
    SEE_DEAL_DETAILS = "see deal details",
    START_PLAYING_VIDEO = "start playing video",
    GO_TO_SAVE_FOR_LATER = "go to save for later",
    CASHBACK_DEALS = "cashback deals",
}

export enum CTAClickLevel {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    TERCIARY = "terciary",
}

export enum CTAClickSubcategory {
    TAKE_QUIZ = "take quiz",
    GET_MATCH_SCORE = "get match score",
    PHONE_HOVER = "phone hover",
    PHONE_NUMBER_CLICKED = "phone number clicked",
    ADD_COMPARE_PRODUCT = " add compare",
    REMOVE_COMPARE_PRODUCT = "remove compare",
    MATTRESS_REVIEW = "mattress review",
    CASHBACK_PROMOTION = "cashback promotion",
    BRAND_PROMOTION = "brand promotion",
    SALE_PROMOTION = "sale promotion",
    ONLINE_PROMOTION = "online promotion",
    FAVORITED = "favorited",
    REMOVE_FAVORITE = "remove favorited",
    SHOW_UPDATE_ZIP_CODE = "show update zip code input",
    UPDATE_ZIP_CODE = "update zip code",
    CLOSE_UPDATE_ZIP_CODE_MODAL = "close update zip code modal",
    EXPAND_PROMO_BOX = "expand promo box",
    COLLAPSE_PROMO_BOX = "collapse promo box",
}

export enum CashbackCodeAction {
    CLICK = "click",
    VIEW = "view",
    MINIMIZE = "minimize",
    MAXIMIZE = "maximize",
    REDEEM = "redeem",
    SAVE = "save",
}

export enum CashbackCodeSource {
    GET_CODE = "get-code",
    EMAIL = "send-email",
    SMS = "send-sms",
    EMAIL_AND_SMS = "send-email-sms",
}

export enum OnlinePromoAction {
    PROMO_REVEAL = "promo reveal",
}

export enum PromoType {
    LOCAL = "local",
    ONLINE = "online",
}

export enum OnlinePromoEngagementType {
    RETAILER = "retailer",
    PRODUCT = "product",
}

export enum AccountAuthenticationAction {
    LOGIN = "log in",
    CREATE_ACCOUNT = "create account",
}

export enum AccountAuthenticationUserType {
    MEMBER = "member",
    ACCOUNT_HOLDER = "account holder",
}

export enum AccountAuthenticationLevel {
    SUPER_ADMIN = "super admin",
    STAFF = "staff",
    REGULAR = "regular",
}

export enum AccountAuthenticationStatus {
    LOGGED_IN = "logged in",
    LOGGED_OUT = "logged out",
    ANONYMOUS = "anonymous",
}

export enum QuizInteractionAnswerTryInStore {
    YES = "yes",
    NO = "no",
    EMPTY = "",
}

export enum QuizInteractionSaveMethod {
    EMAIL = "email",
    TEXT = "text",
    BOTH = "both",
}

export enum QuizInteractionStepEvent {
    START = "start",
    STEP1 = "step 1",
    STEP2 = "step 2",
    STEP3 = "step 3",
    STEP4 = "step 4",
    STEP5 = "step 5",
    STEP6 = "step 6",
    COMPLETE = "complete",
}

export enum QualifyingEventAction {
    QUIZ = "quiz",
    RETAILER_INQUIRY = "retailer-inquiry",
    PROMOTION_CLICK = "promotion-click",
    GET_REBATE_CODE = "get-rebate-code",
    OUTBOUND_LINK = "outbound-link",
}
